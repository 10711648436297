import React from "react";
import { Button } from "@nextui-org/react";
import ContentContainer from "@/app/[locale]/(landingPage)/(homepageLayout)/home/components/ContentContainer";
import Link from "next/link";
import Image from "next/image";
import BackgroundImage from "./background.png";
import ContactFormModal from "../ContactFormModal";
import useDisclosure from "@/hooks/shared/useDisclosure";

const Banner5 = () => {
  const { open, isOpen, close } = useDisclosure();
  return (
    <div className="relative w-full overflow-hidden min-h-[180px] mt-3">
      <Image
        placeholder="blur"
        src={BackgroundImage}
        alt="Background"
        className="-z-10 object-fit-cover w-full h-full position-absolute"
      />

      <div className="relative z-10 flex items-center justify-center h-full">
        <div className="p-8 pt-3 pb-0 lg:px-16 w-full my-4">
          <ContentContainer noPx>
            <div className="flex flex-col lg:flex-row items-start justify-between space-y-6 lg:space-y-0">
              {/* Left Side: Text and Button */}
              <div className="flex md:flex-col items-center lg:items-start text-center lg:text-left space-y-4 max-w-[450px]">
                <h2 className="text-xl lg:text-3xl text-left font-semibold text-adahubSecondary">
                  Tiết kiệm hơn cho doanh nghiệp SMEs và startup
                </h2>
                <Button
                  color="primary"
                  onClick={() => {
                    open();
                  }}
                  className="bg-adahubSecondary px-5 md:px-2 mt-2 md:mt-5 mb-4 text-white rounded-3xl"
                  variant="flat">
                  Đăng kí tư vấn
                </Button>
              </div>

              {/* Right Side: Statistics */}
              <div className="flex sm:gap-2 lg:flex-row items-center justify-center space-y-4 space-x-2 lg:space-y-0 lg:space-x-12 ">
                <div className="text-center  md:mb-5">
                  <h3 className="md:text-5xl text-3xl font-bold text-primary">70%</h3>
                  <p className="md:text-base text-[12px] text-adahubSecondary mt-4 md:mt-3">
                    Giảm chi phí vận hành
                  </p>
                </div>

                <div className="h-20 lg:h-[64px] border-l border-black"></div>

                <div className="text-center">
                  <h3 className="md:text-5xl text-3xl font-bold text-primary">28%</h3>
                  <p className="md:text-base text-[12px] text-adahubSecondary mt-4">
                    Tăng số cơ hội bán hàng mới
                  </p>
                </div>

                <div className="h-20 lg:h-[64px] border-l border-black"></div>

                <div className="text-center">
                  <h3 className="md:text-5xl text-3xl font-bold text-primary">55%</h3>
                  <p className="md:text-base text-[12px] text-adahubSecondary mt-4">
                    Tăng số cơ hội chốt thành công
                  </p>
                </div>
              </div>
            </div>
          </ContentContainer>
        </div>
        <ContactFormModal isOpen={isOpen} open={open} close={close} />
      </div>
    </div>
  );
};

export default Banner5;
